import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//module form npm
import { TranslocoModule } from '@ngneat/transloco';

//module with local source
import { DecentralizationModule } from './directives/zaa-decentralization/decentralization.module';

//services
import { SmartAdminConfigService } from './smart-admin-config.service';

//components
import { ErrorPageComponent } from './components/page-error/error-page.component';
import { SelectLanguageComponent } from './components/zaa-transloco-root/component/select-language/select-language.component';
import { NgxPaginationModule } from './components/lib-ngx/ngx-pagination/public-api';
import { InputMentionComponent } from './components/lib-ngx/ngx-mentions/custom-ngx-mentions/custom-ngx-mentions.component';
import { AppSearchBarComponent } from './components/zaa-search-bar/app-search-bar.component';
import { DatePickerComponentModule } from './components/zaa-date-picker/date-picker.module';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions, MatPaginatorModule } from './components/lib-material/paginator';
import { AppLoadingModuleComponent } from './components/app-loading-module/app-loading-module.component';
import { NgbModalModule } from './components/lib-ng/lib-ng-bootstrap/modal/modal.module';
import { BootBoxComponent } from './components/zaa-bootbox/bootbox.component';
import { FormsModule } from '@angular/forms';
import { NotFoundDataComponent } from './components/app-not-found-data/app-not-found-data.component';
import { DataTableLoaderService } from './components/app-datagird/data-table-loader.service';
import { NgSelect2Module } from './components/lib-ng/lib-ng-select2/src/public_api';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';

// Directives
import { ZaaValidationDirective } from './directives/zaa-validation/zaa-validation.directive';
import { ZaaTooltipDirective } from './directives/zaa-tooltip/zaa-tooltip.directive';
import { ZaaPopoverDirective } from './directives/zaa-popover/zaa-popover.directive';


// Các module kéo source về của material
const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatMenuModule,
  MatOptionModule,
  MatSelectModule,
]

@NgModule({
  declarations: [
    SelectLanguageComponent,
    ErrorPageComponent,
    BootBoxComponent,
    AppLoadingModuleComponent,

    NotFoundDataComponent,

    // directives
    ZaaValidationDirective,
    ZaaTooltipDirective,
    ZaaPopoverDirective,
  ],
  imports: [
    CommonModule,
    DecentralizationModule,
    TranslocoModule,
    NgxPaginationModule,
    InputMentionComponent,
    AppSearchBarComponent,
    DatePickerComponentModule,
    MatPaginatorModule,
    NgbModalModule,
    FormsModule,
    NgSelect2Module,
    ...MATERIAL_MODULES,
  ],
  providers: [
    SmartAdminConfigService,
    DataTableLoaderService, // <-- Enable datagird
  ],
  exports: [
    CommonModule,
    SelectLanguageComponent,
    DecentralizationModule,
    TranslocoModule,
    NgxPaginationModule,
    InputMentionComponent, // <-- Mention feature only use this component.
    AppSearchBarComponent,
    BootBoxComponent,
    DatePickerComponentModule,
    MatPaginatorModule,
    AppLoadingModuleComponent,
    NgbModalModule,
    FormsModule,
    NotFoundDataComponent,
    NgSelect2Module,
    ...MATERIAL_MODULES,

    // directives
    ZaaValidationDirective,
    ZaaTooltipDirective,
    ZaaPopoverDirective,
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        /**
         * truyền vào những service chỉ khởi tạo 1 instant cho toàn bộ app.
         */
        // UserService

      ]
    };
  }
}
