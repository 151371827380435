<main id="js-page-content" role="main" class="page-content">
  <div class="d-flex flex-column align-items-center justify-content-center text-center"
    style="height: 100%; margin-top: 10%;">
    <h1 class="page-error color-fusion-500" *transloco="let translated;">
      {{ translated('error.page.errorLabel') }}
      <span class="text-gradient" *transloco="let translated;">
        {{ translated(statusCode)}}
      </span>
      <small class="fw-500" *transloco="let translated;">
        {{ translated(title)}}
      </small>
    </h1>
    <h3 class="fw-500 mb-5" *transloco="let translated;">
      {{ translated(message)}}
    </h3>

    <div class="w-400">
      <h4 class="text-wrap" style="max-width: 75rem;" *transloco="let translated;">
        {{ translated(desc)}}
      </h4>
    </div>

    <button transloco="error.page.goback" (click)="navigateToHome()" type="button"
      class="btn btn-primary waves-effect waves-themed"></button>
  </div>
</main>
