<ngx-text-input-autocomplete [textInputElement]="textInputElement"
    [menuTemplate]="menuTemplate"
    [mentionsConfig]="mentionsConfig"
    [searchRegexp]="searchRegexp"
    (valueChanged)="valueChanged.emit($event)"
    [closeMenuOnBlur]="closeMenuOnBlur"
    [removeWholeTagOnBackspace]="removeWholeTagOnBackspace"
    [selectedChoices]="selectedChoices"
    (search)="search.emit($event)"
    (choiceSelected)="choiceSelected.emit($event)"
    (choiceRemoved)="choiceRemoved.emit($event)"
    (selectedChoicesChange)="selectedChoicesChange.emit($event)"
    (menuShow)="menuShow.emit()"
    (menuHide)="menuHide.emit()"></ngx-text-input-autocomplete>

<ngx-text-input-highlight [textInputElement]="textInputElement"
    [tags]="mentions"
    [tagCssClass]="tagCssClass"
    [refreshTagHighlighting]="refreshTagHighlighting"
    (tagClick)="tagClick.emit($event)"
    (tagMouseEnter)="tagMouseEnter.emit($event)"
    (tagMouseLeave)="tagMouseLeave.emit($event)"></ngx-text-input-highlight>
