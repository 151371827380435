<div #datepickerInput_single_wrapper *ngIf="!isInline" class="position-relative" [ngClass]="{'input-group':(hasIcon||isRange), 'input-daterange':isRange}" [id]="isRange?'datepicker-wrapper-'+id:''">
  <label class="form-control position-absolute" [ngStyle]="{'width':isRange?'50%':'100%','z-index':0}" [for]="id">{{startDateFormatted ? startDateFormatted : placeholder}}</label>
  <input #datepickerInput_single type="text" (blur)="onTouched()" [disabled]="disabled" [class.disabled]="disabled" [name]="'start'+id" class="form-control" [ngStyle]="{'opacity':'0'}" [id]="id" placeholder={{placeholder}} [readonly]="readonly" />
  <div *ngIf="hasIcon || isRange" class="input-group-append" [ngStyle]="{'z-index':1}" [ngClass]="{'input-group-prepend':isRange}">
    <span class="input-group-text fs-xl">
      <i  [class]="'fal '+ icon" ></i>
    </span>
  </div>
  <label *ngIf="isRange" class="form-control position-absolute" [ngStyle]="{'right':'0','width':'48%','z-index':0}" [for]="'input2'+id">{{endDateFormatted ? endDateFormatted : placeholder}}</label>
  <input #datepickerInput2_single (blur)="onTouched()" [disabled]="disabled" [class.disabled]="disabled" [name]="'end'+id" *ngIf="isRange" type="text" class="form-control" [ngStyle]="{'opacity':0}" [id]="'input2'+id" placeholder={{placeholder}} [readonly]="readonly" >
</div>
<div #datepickerInput_single_inline *ngIf="isInline" class="datepicker_single_inline" [id]="'datepicker-single-inline-'+id"></div>
