<div *ngIf="isLoading">
  <div *ngIf="isLoading" class="container d-flex justify-content-center align-items-center m-auto spinner" [@fadeInOut]>
    <div>
      <div class="spinner-grow text-primary spinner-grow-sm m-1" role="status"></div>
      <div class="spinner-grow text-secondary spinner-grow-sm m-1" role="status"></div>
      <div class="spinner-grow text-success spinner-grow-sm m-1" role="status"></div>
      <div class='loader--text'></div>
    </div>
  </div>

  <div *ngIf="isLoading" class="background" [@fadeInOut]></div>
</div>
