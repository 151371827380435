export const timeout = 200;

export const unicodePatterns: { l: string; s: RegExp }[] = [
    { l: 'a', s: /[ⓐａẚàáâầấẫẩãāăằắẵẳȧǡäǟảåǻǎȁȃạậặḁąⱥɐ]/gi },
    { l: 'aa', s: /ꜳ/gi },
    { l: 'ae', s: /[æǽǣ]/gi },
    { l: 'ao', s: /ꜵ/gi },
    { l: 'au', s: /ꜷ/gi },
    { l: 'av', s: /[ꜹꜻ]/gi },
    { l: 'ay', s: /ꜽ/gi },
    { l: 'b', s: /[ⓑｂḃḅḇƀƃɓ]/gi },
    { l: 'c', s: /[ⓒｃćĉċčçḉƈȼꜿↄ]/gi },
    { l: 'd', s: /[ⓓｄḋďḍḑḓḏđƌɖɗꝺ]/gi },
    { l: 'dz', s: /[ǳǆ]/gi },
    { l: 'e', s: /[ⓔｅèéêềếễểẽēḕḗĕėëẻěȅȇẹệȩḝęḙḛɇɛǝ]/gi },
    { l: 'f', s: /[ⓕｆḟƒꝼ]/gi },
    { l: 'g', s: /[ⓖｇǵĝḡğġǧģǥɠꞡᵹꝿ]/gi },
    { l: 'h', s: /[ⓗｈĥḣḧȟḥḩḫẖħⱨⱶɥ]/gi },
    { l: 'hv', s: /ƕ/gi },
    { l: 'i', s: /[ⓘｉìíîĩīĭİïḯỉǐȉȋịįḭɨı]/gi },
    { l: 'j', s: /[ⓙｊĵǰɉ]/gi },
    { l: 'k', s: /[ⓚｋḱǩḳķḵƙⱪꝁꝃꝅꞣ]/gi },
    { l: 'l', s: /[ⓛｌŀĺľḷḹļḽḻſłƚɫⱡꝉꞁꝇꝆ]/gi },
    { l: 'lj', s: /ǉ/gi },
    { l: 'm', s: /[ⓜｍḿṁṃɱɯ]/gi },
    { l: 'n', s: /[ⓝｎǹńñṅňṇņṋṉƞɲŉꞑꞥ]/gi },
    { l: 'nj', s: /ǌ/gi },
    { l: 'o', s: /[ⓞｏòóôồốỗổõṍȭṏōṑṓŏȯȱöȫỏőǒȍȏơờớỡởợọộǫǭøǿɔƟꝋꝍɵ]/gi },
    { l: 'oi', s: /ƣ/gi },
    { l: 'oe', s: /œ/gi },
    { l: 'oo', s: /ꝏ/gi },
    { l: 'ou', s: /ȣ/gi },
    { l: 'p', s: /[ⓟｐṕṗƥᵽꝑꝓꝕ]/gi },
    { l: 'q', s: /[ⓠｑɋꝗꝙ]/gi },
    { l: 'r', s: /[ⓡｒŕṙřȑȓṛṝŗṟɍɽꝛꞧꞃ]/gi },
    { l: 's', s: /[ⓢｓßẞśṥŝṡšṧṣṩșşȿꞩꞅẛ]/gi },
    { l: 't', s: /[ⓣｔṫẗťṭțţṱṯŧƭʈⱦꞇ]/gi },
    { l: 'tz', s: /ꜩ/gi },
    { l: 'u', s: /[ⓤｕùúûũṹūṻŭüǜǘǖǚủůűǔȕȗưừứữửựụṳųṷṵʉ]/gi },
    { l: 'v', s: /[ⓥｖṽṿʋꝟʌ]/gi },
    { l: 'vy', s: /ꝡ/gi },
    { l: 'w', s: /[ⓦｗẁẃŵẇẅẘẉⱳ]/gi },
    { l: 'x', s: /[ⓧｘẋẍ]/gi },
    { l: 'y', s: /[ⓨｙỳýŷỹȳẏÿỷẙỵƴɏỿ]/gi },
    { l: 'z', s: /[ⓩｚźẑżžẓẕƶȥɀⱬꝣ]/gi },
];

export const defaultMinCountForSearch = 6;

export const protectRegexp = new RegExp('[\\-\\[\\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\\\^\\$\\|]', 'g');
